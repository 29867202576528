@import 'variables';
@import 'colors';

.mat-menu-item {
	color: #595959 !important;
	font-family: $secondary-font-family;
	font-weight: 500;
	font-size: 12px;
	height: 42px;
}
.mat-menu-content {
	padding: 5px 0 !important;
}
.hot-toast-bar-base.bs {
	position: relative;

	.hot-toast-message {
		margin: var(--hot-toast-message-margin, 4px 10px 4px 2px);
	}
	dynamic-view div {
		font-size: 12px;
		font-family: 'Open Sans';
		font-weight: 600;
	}

	&.success {
		color: #006816 !important;

		border: 1px solid rgba(23, 173, 55, 1) !important;
		&::after {
			background: rgba(23, 173, 55, 0.14) !important;
		}

		.hot-toast-close-btn {
			--hot-toast-close-btn-background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='green'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
		}
	}

	&.warning {
		color: #f8a900 !important;

		border: 1px solid rgba(255, 153, 0, 1) !important;
		&::after {
			background: rgba(255, 153, 0, 0.14) !important;
		}
		.hot-toast-close-btn {
			--hot-toast-close-btn-background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='orange'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
		}
	}

	&.error {
		color: #d0021b !important;

		border: 1px solid rgba(215, 2, 27, 1) !important;
		&::after {
			background: rgba(215, 2, 27, 0.14) !important;
		}

		.hot-toast-close-btn {
			--hot-toast-close-btn-background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='red'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
		}
	}

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: -1;
		top: 0;
		left: 0;
	}
}

.mat-form-field-infix {
	border-top: 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
	padding: 1.45em 0;
	display: flex !important;
	align-items: center !important;
}

.mat-input {
	font-size: 9px;
}

mat-error {
	font-size: 10px;
}

.mat-select-search-no-entries-found {
	padding: 0 !important;
	position: relative;
	left: 8px;
	top: 5px;
}

.mat-select-search-inside-mat-option .mat-select-search-clear {
	opacity: 0;
}

mat-option {
	position: relative;
	padding-bottom: 5px !important;

	ngx-mat-select-search {
		position: relative !important;
		z-index: 0;
	}

	[bsClearSelect] {
		display: flex !important;
		justify-content: flex-end;
		position: relative;
		width: 98% !important;
		transform: translateY(185%);
		z-index: 1;
		color: gray;
		cursor: pointer;
		font-size: 14px;
	}
}

.no-search {
	position: absolute;
	z-index: 1;
	right: 5px;
	top: 8.5px;
	font-size: 16px;
}

.mat-drawer-inner-container {
	overflow-x: hidden;
	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	&::-webkit-scrollbar-track {
		-webkit-border-radius: 10px;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		-webkit-border-radius: 10px;
		border-radius: 10px;
		background: white;
	}
}

button.mat-menu-item {
	background-color: white !important;
}

button.mat-menu-item:hover {
	background-color: $primary-blue !important;
	color: #fff !important;
}

.generic-field.total[disabled='true'] mat-form-field {
	&::after {
		background-color: #2ebd59;
		opacity: 0.4;
		padding-bottom: 3px;
	}
}

.mat-calendar-body-label {
	padding-bottom: 0 !important;
}

.mat-raised-button {
	box-shadow: none !important;

	&:disabled {
		color: rgba(255, 255, 255, 0.5) !important;
	}
}

.generic-field[disabled='true'] mat-form-field {
	position: relative;

	&::after {
		content: '';
		position: absolute;
		top: 3px;
		left: 0;
		padding-bottom: 4px;
		width: 100%;
		height: 100%;
		max-height: 40px;
		background-color: #f2f2f2;
		border-radius: 4px;
		opacity: 0.7;
	}
}

.mat-form-field-appearance-outline.mat-form-field-disabled {
	.mat-form-field-outline-start {
		border-color: #f2f2f2;
	}

	.mat-form-field-outline-gap {
		border-color: #f2f2f2;
	}

	.mat-form-field-outline-end {
		border-color: #f2f2f2;
	}
	.mat-form-field-flex {
		position: relative;
		&::before {
			position: absolute;
			content: '';
			left: 0;
			top: 3px;
			width: 100%;
			height: 92.5%;
			background: #f2f2f2;
			border-radius: 5px;
		}
	}
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
	color: #aaaa;
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
	color: $primary-blue !important;
}

.mat-select {
	font-size: minmax(8px, 12px);
}

.mat-card-content {
	margin-bottom: 10px;
}

.mat-progress-bar-buffer {
	background-color: #f2f2f2;
}

.mat-input-element {
	font: menu;
	color: rgba(150, 150, 150, 0.4);
	&::placeholder {
		color: #595959 !important;
		font-weight: 700 !important;
	}
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
	padding: 0px !important;
	padding-bottom: 5px !important;
}
.mat-date-range-input-container {
	align-items: unset !important;
}
.timepicker {
	--primary-font-family: 'Open Sans', sans-serif;
	--button-color: #005ae6;
	--dial-background-color: #005ae6;
	--dial-editable-active-color: #005ae6;
	--clock-hand-color: #005ae6;

	width: 320px !important;
}

.timepicker-dial__control {
	font-size: 40px !important;
	text-align: center !important;
}

.mat-timer-field {
	border: 1px solid #f2f2f2;
	border-radius: 5px;
	margin-top: 5px;
	height: 43px;
	transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
	position: relative;

	&.focus {
		box-shadow: 0px 0px 0px 2px $primary-blue !important;
	}

	&:not([disabled='true']):hover {
		box-shadow: 0px 0px 0px 2px #adadad;
	}

	&.has-error {
		box-shadow: 0px 0px 1px 2px red !important;

		ngx-material-timepicker-toggle {
			position: relative;
			width: 100%;
			height: 100%;

			&::before {
				position: absolute;
				content: '';
				width: 13px;
				right: 32px;
				top: -0.5px;
				padding: 1px;
				height: 100%;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100%;
				background-image: url('../assets/svgs/error-icon.svg');
			}
		}
	}

	mat-error {
		margin-top: 9px;
	}

	.ngx-timepicker {
		box-shadow: none;
		position: relative;
		border-bottom: none !important;

		.ngx-timepicker-control--active::after {
			display: none;
		}

		input,
		span {
			font-size: 12px;
		}
		.ngx-timepicker-control__arrows {
			position: absolute;
			right: 2px;
			top: 0;
			width: 15px;
			height: 27px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
		}
		.ngx-timepicker-control__arrow {
			margin-top: 2px;
			font-size: 9px !important;
		}
		.ngx-material-timepicker-toggle {
			position: absolute;
			right: 7px;
			top: 8px;
			height: 24px;
		}
		svg {
			margin-top: 2px;
			width: 16px;
		}
	}
}

mat-form-field {
	color: #595959 !important;
}

.anexar-arquivo {
	margin-top: 1.3px;
	height: 88px;
	.file-input {
		margin-top: 7px;
		width: 100%;
		display: flex;
		background-color: rgba(0, 0, 0, 0.06);
		align-items: center;
		border-radius: 4px;
	}
	input,
	button {
		color: #595959 !important;
		border: none;
		height: 100%;
	}
	input {
		text-align: start;
		padding-left: 13px;
		font-size: 9.5px;
		width: 100%;
	}
	button {
		color: #595959;
		text-align: center;
		border-left: none;
		padding: 0px 11px;
		width: 86px;
		background: #d1d1d1;
		border-radius: 0px 4px 4px 0px;

		&:hover {
			background: #e4e4e4;
		}
	}
}

.mat-form-field:not(.mat-form-field-appearance-legacy)
	.mat-form-field-prefix
	.mat-icon-button
	.mat-datepicker-toggle-default-icon,
.mat-form-field:not(.mat-form-field-appearance-legacy)
	.mat-form-field-suffix
	.mat-icon-button
	.mat-datepicker-toggle-default-icon {
	margin: auto;
	margin-bottom: 1.1px;
}
.mat-select-arrow-wrapper {
	top: 4.8px;
	background-size: 100%;
	position: relative;
	padding: 1.2px;
	left: -5px;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url('../assets/svgs/select-arrow.svg');
	.mat-select-arrow {
		border: none !important;
	}
}
.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
	top: 0.55em !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid {
	.mat-select-arrow-wrapper {
		left: -25px;
	}

	.mat-form-field-infix {
		color: #595959 !important;
	}

	&.timer-field .mat-form-field-suffix::before {
		z-index: 1;
		position: absolute;
		content: '';
		width: 13px;
		top: 1.22px;
		right: 27px;
		padding: 1px;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100%;
		background-image: url('../assets/svgs/error-icon.svg');
	}
	.mat-form-field-infix {
		mat-datepicker {
			position: relative;

			&::after {
				content: '';
				z-index: 0;
				position: absolute;
				width: 20px;
				top: -3.5px;
				right: 1px;
				padding: 2px;
				height: 90%;
				background-color: white;
			}

			&::before {
				z-index: 1;
				position: absolute;
				content: '';
				width: 13px;
				top: 1px;
				right: 1px;
				padding: 1px;
				height: 100%;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100%;
				background-image: url('../assets/svgs/error-icon.svg');
			}
		}

		& .mat-select {
			position: relative;

			&::before {
				z-index: 1;
				position: absolute;
				content: '';
				width: 13px;
				top: -1px;
				right: 1px;
				padding: 1px;
				height: 15px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100%;
				background-image: url('../assets/svgs/error-icon.svg');
			}
		}
	}
}

.mat-select-search-panel {
	opacity: 1;
	min-width: calc(100% + 12px) !important;
	position: relative;
	left: 10px;
}

.mat-form-field-input-title {
	min-width: 40px;
	min-height: 19px;
	font-size: $mat-form-field-font-size;
	line-height: 19px;
	font-family: $primary-font-family;
	color: $sub-title-primary-color;
	font-weight: 600;
}
.mat-form-field-input-form-label {
	min-width: 227px;
	word-break: break-all;
	font-family: $primary-font-family !important;
	font-weight: 600 !important;
	font-size: clamp(11px, 0.5vw, 12px) !important;
	line-height: 16px !important;
	color: #595959 !important;
	margin-bottom: 2px !important;

	mat-icon {
		position: relative;
		top: 2px;
		text-align: center;
		font-size: 14px;
		height: 16px;
	}
}

.normal {
	color: #000000;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
	margin-left: 8px;
}

bs-cadastro-aditivo-table .mat-expansion-panel-body,
bs-cadastro-contrato-table  .mat-expansion-panel-body  {
  padding: 0 !important;

	tr.mat-row td.mat-cell {
		padding: 0 !important;
	}
}

.cdk-overlay-pane.aditivo-dialog {
  overflow-y: auto !important;
}

button.mat-button-toggle-button.mat-focus-indicator .mat-button-toggle-label-content {
	line-height: 35px !important;
	font-size: 12px;
	font-weight: 500;
}

bs-historico-table div.mat-expansion-panel-body,
.cdk-overlay-pane.aditivo-dialog mat-dialog-container {
  padding: 0;
}

bs-historico-table mat-accordion mat-expansion-panel {
  margin-top: 24px !important;
}
