$primary-blue: #005ae6;
$primary-light-blue: rgba(0, 82, 204, 0.03);
$primary-linear-gradient: -webkit-linear-gradient(#21d4fd, #2152ff);
$secondary-linear-gradient: linear-gradient(135deg, #21d4fd 0%, #005ae6 100%);
$warn-red: #ff4141;
$dark-blue: #3a416f;
$white: #ffff;
$sub-title-primary-color: #595959;
$primary-border-color: #e9ecef;
$light-gray: #979797;
$gray: #8c8c8c;
$prioridade-imediata-color: #ff3838;
$prioridade-alta-color: #7928ca;
$prioridade-media-color: #ffb302;
$prioridade-baixa-color: #2ebd59;
$prioridade-programado-color: #2dccff;
$table-chamado-cell-color: rgba(0, 82, 204, 1);
$table-header-color: #f2f2f2;
$dark-gray: rgba(88, 87, 87, 1);
$dark-gray-secondary: rgba(89, 89, 89, 1);
$dark-gray-hover: rgba(233, 233, 239, 0.3);
$table-text: rgba(89, 89, 89, 0.7);
$background-color: #f8f9fa;
$gray-color-border: #e9e9ef;
$form-label-color: #252f40;
$disabled-input-backgroud: #676767;
$disabled-input-text: #a1a1a1;
$light-text: #ffffff;
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$dark-text: #000000;
