/* You can add global styles to this file, and also import other style files */
@import 'material';
@import 'reset';
@import 'variables';
@import 'colors';
@import 'overrides';

html,
body {
	margin: 0;
	height: 100%;
	font-family: 'Open Sans', sans-serif;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline {
	color: red !important;
	opacity: 1 !important;
}

.theme-alternate {
	bs-home,
	bs-abrir-chamado,
	bs-search,
	bs-abrir-chamado-form,
	bs-lancar-chamado,
	bs-cadastro-cliente,
	app-cadastro-cliente-form,
	bs-cadastro-contrato,
	bs-cadastro-equipamento,
	bs-cadastro-usuario,
	bs-grupo,
	bs-associacao,
	bs-acessos,
	bs-contas,
	bs-cadastro-colaborador,
	bs-perfil,
	bs-warning-modal,
	bs-abrir-chamado-dialog,
	bs-cadastro-contrato-search,
	mat-expansion-panel.darkMode-5 .mat-expansion-panel-body {
		background-color: $dark-bg-lighter-5 !important;
		color: $light-text !important;
	}

	bs-footer,
	.darkMode-default,
	.mainHeader,
	th[mat-header-cell] {
		background-color: $dark-background !important;
		color: $light-text !important;
	}

	.darkMode-5 {
		background-color: $dark-bg-lighter-5 !important;
		color: $light-text !important;
	}

	.darkMode-10 {
		background-color: $dark-bg-lighter-10 !important;
		color: $light-text !important;
	}

	textarea,
	.form-field,
	.ngx-spinner-overlay,
	.darkMode-20 {
		background-color: $dark-bg-lighter-20 !important;
		color: $light-text !important;
	}

	.text-area-field .mat-form-field-outline {
		border-radius: 5px;
		background-color: $dark-bg-lighter-20 !important;
	}

	mat-select,
	input,
	.mat-select-placeholder,
	.mat-select-min-line,
	.mat-form-field-input-form-label,
	.section-title,
	textarea,
	.form-field,
	td[mat-cell],
	.chamado-detail.row span,
	.mat-button-wrapper,
	.cloumnsTrigger,
	input:disabled,
	.section-card span,
	.section-card h1,
	.lightText,
	mat-checkbox,
	ng-component p,
	ng-component p span,
	p,
	p span,
	.chamado-detail .row span,
	mat-radio-button,
	.warningTitle,
	.mat-expansion-panel-body mat-checkbox,
	.dynamicFields span,
	.text,
	.darkModeText,
	.darkModeText span {
		color: $light-text !important;
	}

	input::placeholder {
		color: $light-text !important;
		font-weight: 500 !important ;
	}

	.noBorder,
	section.white-card {
		border: none !important;
	}

	.mat-form-field-appearance-outline.mat-form-field-disabled {
		.mat-form-field-outline-start,
		.mat-form-field-outline-gap,
		.mat-form-field-outline-end {
			border: none !important;
		}

		.mat-select-min-line {
			color: $disabled-input-text !important;
		}

		&::placeholder {
			color: $disabled-input-text !important;
		}
		.mat-form-field-flex {
			&::before {
				background: $disabled-input-backgroud;
			}
		}
		input,
		input::placeholder {
			color: $disabled-input-text !important;
			border: none;
		}
	}

	.section-tilte-card {
		font-weight: 500 !important;
	}

	textarea:disabled {
		color: $disabled-input-text !important;
		&::placeholder {
			color: $disabled-input-text !important;
		}
	}

	mat-datepicker::after {
		background-color: transparent !important;
	}

	.mat-menu-panel {
		box-shadow: none !important;
	}

	button.mat-menu-item {
		background-color: $dark-bg-lighter-5 !important;
		color: $light-text !important;
		&:hover {
			background-color: $primary-blue !important;
		}
	}

	.mat-button-disabled {
		.mat-button-wrapper {
			color: $light-gray !important;
		}
	}

	.mat-elevation-z8 {
		border-radius: 0% !important;
	}

	bs-chamado-card {
		&.aberto {
			p {
				color: lighten(#9a9a9a, 50%) !important;
			}
		}
		&.andamento {
			p {
				color: lighten(#ffb302, 10%) !important;
			}
		}
		&.aguardando {
			p {
				color: lighten(#2dccff, 10%) !important;
			}
		}
		&.parados {
			p {
				color: lighten(#7928ca, 30%) !important;
			}
		}
		&.cancelados {
			p {
				color: lighten(#ea0606, 20%) !important;
			}
		}
		&.finalizados {
			p {
				color: lighten(#15772a, 20%) !important;
			}
		}
	}

	ngx-material-timepicker-toggle button svg {
		filter: brightness(0) invert(1);
	}
}

.cdk-overlay-pane.equipamento-dialog {
	overflow-y: auto;
}

.cdk-overlay-pane.colaborador-dialog {
	overflow-y: auto;
}

.no-data {
	display: flex;
	flex-direction: column;
	align-items: center;
	h1 {
		margin-bottom: 20px;
		color: #595959 !important;
		font-weight: 500 !important;
	}
}
mat-form-field mat-error {
	font-size: clamp(8px, 0.5vw, 10px) !important;
	word-break: break-word;
}

.button-group {
	.mat-raised-button:hover {
		background-color: #00b0fc !important;
	}
	.mat-stroked-button:hover {
		background-color: #00b0fc !important;
	}

	button:hover {
		color: white !important;
		background-color: #00b0fc !important;
		border-color: #00b0fc !important;
		&.mat-warn {
			background-color: #f29913 !important;
		border-color: #f29913 !important;
		}
	}
}

.mat-menu-panel {
	min-height: auto !important;
}

.button-primary,
.button-secondary {
	width: unset !important;
	min-height: 40px !important;
	padding: 0px 15px !important;
}

.highlight-text {
	letter-spacing: 0.3px;
}
.mat-stroked-button {
	padding: 0.1px 0px;
}
.mat-stroked-button,
.mat-raised-button {
	letter-spacing: 0.5px;
	box-shadow: none !important;
}

.line {
	width: 100%;
	height: 1px;
	background-color: #e9ecef;
	margin-top: 22px;
	margin-bottom: 20px;
	&.last {
		margin-top: 10px;
		margin-bottom: 10px;
	}
}

.column-line {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.highlight-text {
	font-weight: 700;
	font-size: 14px !important;
	line-height: 19px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	color: $primary-blue;
	margin-bottom: 11px;
}

.section-title {
	font-weight: 700 !important;
	color: #3a416f;
	font-size: 18px !important;
	line-height: 25px !important;
	margin-bottom: 12px !important;
}

mat-form-field {
	width: 100%;
	font-size: $mat-form-field-font-size !important;
	margin-bottom: 9px;
}

.mat-select-value {
	color: #595959 !important;
}

input[disabled] {
	font-weight: 400 !important;
	color: #595959 !important;

	&::placeholder {
		font-weight: 400 !important;
		color: #595959 !important;
	}
}

section.white-card {
	width: 100%;
	background-color: $white;
	border-radius: 8px;
	border: 1px solid $gray-color-border;
	padding: 20px 19px;
	margin-bottom: 20px;
}

.mat-date-range-input-container {
	position: relative;
	top: 2px;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

::-webkit-scrollbar-track {
	background-color: $sub-title-primary-color;
	-webkit-border-radius: 8px;
	border-radius: 8px;
}

::-webkit-scrollbar-thumb {
	-webkit-border-radius: 8px;
	border-radius: 8px;
	background: $light-gray;
}

span.quantity {
	margin-left: 5px;
	font-size: 12px;
	font-weight: 600;
	color: #00b0fc;
}

.not-found {
	font-family: $primary-font-family;
	margin-top: 69px;
	margin-bottom: 69px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.illustration {
		width: 200px;
		height: 180px;
	}

	span {
		margin-top: 13px;
		margin-bottom: 26px;
	}

	button {
		border-radius: 5px;
		border: 1.8px solid $primary-blue !important;
	}

	.mat-stroked-button {
		color: $primary-blue !important;
		padding: 2px 10px;
		font-weight: 800 !important;
		font-size: 14px !important;
		max-width: 220px;
	}
}

.ngx-material-timepicker-toggle {
	padding: 0px !important;
	position: relative;
	top: 2px;
	transform: scale(0.7);
}

.not-found-equipamento {
	margin-top: 10px;
	font-family: $primary-font-family;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	.illustration {
		width: 200px;
		height: 180px;
	}
	span {
		font-size: 16px;
	}
	.message {
		color: rgba(89, 89, 89, 1);
		font-size: 16px !important;
		text-transform: unset !important;
		line-height: 0 !important;
		margin-top: 5px;
		margin-bottom: 15px;
	}

	.mat-stroked-button {
		color: $primary-blue !important;
		line-height: 0.6px;
	}

	button {
		margin-top: 10px;
		text-align: center;

		max-width: 150px;

		img {
			margin-bottom: 4.5px;
		}
	}
}

.anexar-arquivo {
	h1 {
		margin-bottom: 0px !important;
	}
	.file-input {
		margin-top: 3px;
		width: 100%;
		display: flex;
		background-color: rgba(0, 0, 0, 0.06);
		align-items: center;
		height: 42.5px;
		border-radius: 5px;
	}
	input,
	button {
		border: none;
		height: 100%;
	}
	input {
		font-size: 9.5px;
		width: 100%;
		color: rgba(89, 89, 89, 1);
	}
	button {
		color: #595959;
		text-align: center;
		border-left: none;
		padding: 0px 11px;
		font-size: 12px;
		width: 86px;
		background: #d1d1d1;
		border-radius: 0px 4px 4px 0px;

		&:hover {
			background: #e4e4e4;
		}
	}
}
button {
	font-family: 'Open Sans', sans-serif !important;
	font-weight: 700 !important;
	letter-spacing: 0 !important;
}

.dot {
	height: 10px;
	width: 10px;
	background-color: #bbb;
	border-radius: 50%;
	margin-right: 5px;
	display: inline-block;
	cursor: pointer;
}

@media screen and (max-width: 600px) {
	app-side-nav-fechada {
		display: none !important;
	}

	.mainContent {
		padding-left: 0px !important;
	}
}


@media print{
	@page { margin: 0; }
    body { margin: 1.6cm; }

	div.relatorioChamado {
		max-width: 100vw !important;
		height: 100vh !important;
		width: 100vw !important;
	}
}